<template>
  <div class="checkbox">
    <div class="butbox" @click.stop="butMass3($event)" :data-class = 'arr[2]'>
      <div class="but">
      </div>
      <span class="span1">{{ arr[1] }}</span>
      <span class="span2">{{ arr[0] }}</span>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "checkBox",
  props: ['arr'],
  data() {
    return {
      voice: false,
    }
  },
  mounted() {},
  watch: {
  },
  computed: {},
  methods: {
    butMass3(e) {
      if(e.currentTarget.getAttribute('data-class') === 'voice'){
        if (this.voice === false) {
          this.voice = true
          this.$root.$emit('checkbox', true)
          $(e.currentTarget).css({transform: 'translate(-6px,0)', transition: 'all 0.5s'})
        } else if (this.voice === true) {
          this.voice = false
          this.$root.$emit('checkbox', false)
          $(e.currentTarget).css({transform: 'translate(-44px,0)', transition: 'all 0.5s'})
        }
      }else if(1){

      }
    }
  },
}
</script>

<style scoped>
.checkbox {
  position: relative;
  width: 72px;
  height: 34px;
  border: 1px solid #d4d8de;
  border-radius: 30px;
  overflow: hidden;
}
.butbox {
  position: relative;
  width: 120px;
  height: 34px;
  transform: translate(-44px, 0);
  cursor: pointer;
}
.but {
  z-index: 3;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -1px);
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #E3E3E3;
  background: white;
}
span {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-family: 'Times New Roman', serif;
}
.span1 {
  left: 0;
  background: #0383cb;
  padding-left: 6px;

}
.span2 {
  right: 0;
  background: white;
  padding-left: 18px;
}
</style>
